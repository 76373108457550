<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
          <c-select
            :editable="editable"
            :comboItems="useFlagItems"
            label="사용여부"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            type="search"
            v-model="searchParam.useFlag">
          </c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <c-table
          ref="table"
          title="적산전력계 목록"
          tableId="table"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :columnSetting="false"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <q-form ref="editForm">
          <c-card title="상세" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="editable" label="등록" icon="add" @btnClicked="addData" />
                <c-btn
                  v-if="editable && saveable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="saveType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-6">
                <c-plant 
                  :required="true"
                  :editable="editable && dataeditable" 
                  type="edit" 
                  name="plantCd" 
                  v-model="data.plantCd" />
              </div>
              <div class="col-6">
                <c-text
                  :editable="editable && dataeditable"
                  :required="true"
                  label="적산전력계 번호"
                  name="envAirMstWattmeterNo"
                  v-model="data.envAirMstWattmeterNo">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  :editable="editable && dataeditable"
                  :required="true"
                  label="적산전력계 구분명"
                  name="envAirMstWattmeterName"
                  v-model="data.envAirMstWattmeterName">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  :editable="editable && dataeditable"
                  :required="true"
                  label="배율"
                  name="wattmeterMagnification"
                  v-model="data.wattmeterMagnification">
                </c-text>
              </div>
              <div class="col-6">
                <c-checkbox
                  :editable="editable && dataeditable"
                  :isFlag="true"
                  label="사용여부"
                  name="useFlag"
                  v-model="data.useFlag"
                />
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'air-master-09',
  props: {
    param: {
      type: Object,
      default: () => ({
      }),
    },
  },
  data() {
    return {
      data: {
        plantCd: null,  // 사업장코드
        envAirMstWattmeterId: '',  // 일련번호
        envAirMstWattmeterNo: '',  // 적산전력계 번호
        envAirMstWattmeterName: '',  // 적산전력계 구분명
        wattmeterMagnification: '1',  // 배율
        useFlag: 'Y',  // 사용여부
      },
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
      },
      useFlagItems: [],
      editable: true,
      listUrl: '',
      detailUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      saveable: false,
      dataeditable: false,
      updateMode: false,
      isSave: false,
      saveUrl: transactionConfig.env.air.mst.drug.insert.url,
      saveType: 'POST',
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width: 120px',
            sortable: true,
          },
          {
            name: 'envAirMstWattmeterNo',
            field: 'envAirMstWattmeterNo',
            label: '적산전력계번호',
            align: 'center',
            style: 'width: 150px',
            sortable: true,
          },
          {
            name: 'envAirMstWattmeterName',
            field: 'envAirMstWattmeterName',
            label: '전력계구분명',
            align: 'left',
            sortable: true,
          },
          {
            name: 'wattmeterMagnification',
            field: 'wattmeterMagnification',
            label: '배율',
            align: 'center',
            style: 'width: 120px',
            sortable: true,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width: 120px',
            sortable: true,
          },
        ],
        data: [],
      },
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.useFlagItems = [
        { code: 'Y', codeName: this.$language('사용') },
        { code: 'N', codeName: this.$language('미사용') },
      ]
      this.editable = this.$route.meta.editable;
      this.dataeditable = false;
      // api scope
      this.listUrl = selectConfig.env.air.mst.wattmeter.list.url;
      this.detailUrl = selectConfig.env.air.mst.wattmeter.get.url;
      this.insertUrl = transactionConfig.env.air.mst.wattmeter.insert.url;
      this.updateUrl = transactionConfig.env.air.mst.wattmeter.update.url;
      this.deleteUrl = transactionConfig.env.air.mst.wattmeter.delete.url;
      this.getDataList();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getDataList() {
      this.getList();
      this.rowRemoveSelect();
      this.reset();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
      this.saveable = false;
    },
    rowClick(row) {
      // 상세조회
      this.saveable = true;
      this.updateMode = true;
      this.$http.url = this.detailUrl;
      this.$http.param = {envAirMstWattmeterId: row.envAirMstWattmeterId}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
        this.dataeditable = true;
      },
      () => {
      });
    },
    addData() {
      this.saveable = true;
      this.saveUrl = this.insertUrl;
      this.saveType = 'POST';
      this.updateMode = false;
      this.dataeditable = true;
      this.data = {
        plantCd: this.searchParam.plantCd,  // 사업장코드
        envAirMstWattmeterId: '',  // 일련번호
        envAirMstWattmeterNo: '',  // 적산전력계 번호
        envAirMstWattmeterName: '',  // 적산전력계 구분명
        wattmeterMagnification: '1',  // 배율
        useFlag: 'Y',  // 사용여부
      };
      this.rowRemoveSelect();
    },
    reset() {
      this.rowRemoveSelect();
      this.saveable = false;
      this.updateMode = false;
      this.dataeditable = false;
      this.data = {
        plantCd: null,  // 사업장코드
        envAirMstWattmeterId: '',  // 일련번호
        envAirMstWattmeterNo: '',  // 적산전력계 번호
        envAirMstWattmeterName: '',  // 적산전력계 구분명
        wattmeterMagnification: '',  // 배율
        useFlag: 'Y',  // 사용여부
      };
    },
    saveData() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId
              this.data.chgUserId = this.$store.getters.user.userId

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDataList();
    },
  }
};
</script>
